import { Loader } from "@mantine/core"
import { useEffect } from "react"

export default ({children , onClick ,enter=false, orange=false,red=false,loading=false}:{children:any , onClick:any, orange?:boolean,red?:boolean,loading?:boolean,enter?:boolean}) => {
    // useEffect  (() => {
    //     if(enter){
    //     window.addEventListener('keydown', (e) => {
            
    //         if (e.key === 'Enter') {
    //             onClick()
    //         }
    //     } )
    //     }
    // } , [])
    return (
        <button  
        className={
            orange?
            "bg-gradient-to-tr flex justify-center items-center from-red-500 to-orange-500 shadow-md rounded-lg hover:bg-gradient-to-l duration-300 hover:shadow-3xl hover:scale-105  text-white font-bold w-full py-2"
            :red?
            "bg-gradient-to-tr flex from-red-700 justify-center items-center to-red-900 shadow-md rounded-lg hover:bg-gradient-to-l duration-300 hover:shadow-3xl hover:scale-105  text-white font-bold w-full py-2"
            :
            "bg-gradient-to-tr flex from-purple-700 justify-center items-center to-indigo-700 shadow-md rounded-lg hover:bg-gradient-to-l duration-300 hover:shadow-3xl hover:scale-105  text-white font-bold w-full py-2"
        }
        onClick={onClick}>
            {loading?<Loader color={'gray'}/>:children}
        </button>
    )
}