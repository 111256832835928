import { api } from "./config"
export const dealerlogin = async (name: string, password: string,ott:string) => await api.get("/dealer",{headers: {name , password,ott}})      
export const createdealer = async (name: string,cat:string,phone:string,password:string,image:File,admin:string) =>{
    var formdata = new FormData();
    formdata.append('name', name);
    formdata.append('cat', cat);
    formdata.append('phone', phone);
    formdata.append('password', password);
    formdata.append('file', image);
    
    await api.post("/dealers", formdata,{
    headers: {
        admin: admin
    }
}
)}
export const getdealers = async (admin:string) => await api.get("/dealers",{headers: {admin}})