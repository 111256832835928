import { useRouter } from "next/router"
import {  useState,useEffect, ReactChild } from "react"
import { createStore } from "redux"


export default ({children,setUserData}:{children:ReactChild,setUserData:Function}) => {
const auth = useAuth()
const router = useRouter()
const [render,setRender] = useState(false)
useEffect(()=>{
    setUserData(auth?.user)
    if(auth.isAuthenticated){
        setRender(true)
    }else{
       router.push("/login")
    }
},[auth])
    return (
        <>
            {render && children}
        </>
    )
}

const initialState = {
    isAuthenticated: false,
    user: null,
    associations:[]
}

const reducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            }
        case "LOGOUT":
            return {
                ...state,
                isAuthenticated: false,
                user: null
            }
        case "AddLISTING":
            return {
                ...state,
                user: {
                    ...state.user,
                    associations: [action.payload,...state.user.associations]
                }
            }
        default:
            return state
    }
}

const store = createStore(reducer)

export const AddListing = (listing: any) => {
    store.dispatch({ type: "AddLISTING", payload: listing })
}

export const useAuth = () => {
    const [state,setState] = useState(store.getState())
    useEffect(()=>{
        store.subscribe(()=>{
            setState(store.getState())
        })
    }
    ,[])
    return state
}

const userlogin = (user: any) => {
    localStorage.setItem("7stardealercreds", JSON.stringify({name:user.name,password:user.password}))
    store.dispatch({
        type: "LOGIN",
        payload: user
    })
}

const userlogout = () => {
    localStorage.setItem("7stardealercreds", "")
    store.dispatch({
        type: "LOGOUT",
        payload: null
    })
}

export { userlogin, userlogout }
