import { Input, Loader } from "@mantine/core"
import { useInputState } from "@mantine/hooks"
import { useRouter } from "next/router"
import { useLayoutEffect } from "react"
import { dealerlogin } from "../../api/User"
import Btn from "../../layout/Btn"
import LogoContainer from "../../layout/LogoContainer"
import { userlogin } from "../../providers/ValidUser"

export default () => {
    const [username, setusername] = useInputState('')
    const [password, setpassword] = useInputState('')
    const [logging, setlogging] = useInputState(false)
    const [error, seterror] = useInputState('')
    const router = useRouter()
    useLayoutEffect(()=>{
        if(localStorage.getItem('7stardealercreds')){
            var dd = JSON.parse(localStorage.getItem('7stardealercreds'))
            var ott = localStorage.getItem('7stardealerott')
            setlogging(true);
            (async () => {
                try{
                const res = await dealerlogin(dd.name, dd.password,ott)
                if (res?.data !== null) {
                    userlogin(res.data)
                    router.push(`/login/${res.data?.name}`)
                } else {
                    seterror('Invalid username or password')
                }
            }catch(e){
                setlogging(false);
                seterror('Invalid username or password')
            }
            })()
        }
    },[])
    const handleSubmit = async () => {
        const randomhash = localStorage.getItem('7stardealerott') ? localStorage.getItem('7stardealerott') : Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        setlogging(true);
        (async () => {
            try{
            const res = await dealerlogin(username, password,randomhash)
            if (res?.data !== null) {
                localStorage.setItem('7stardealerott',randomhash)
                userlogin(res.data)
                router.push(`/login/${res.data?.name}`)
            } else {
                seterror('Invalid username or password')
            }
        }catch(e){
            setlogging(false);
            seterror('Device Unauthorized')
        }
        })()
    }

    return (
        <div className="flex h-screen justify-center items-center">
            <div className="p-5 w-96 bg-white rounded-3xl flex flex-col shadow-xl justify-center text-center">
                {logging ?
                    <h1 className="font-bold text-lg "><Loader /></h1>
                    :
                    <>
                        <div className="bg-gradient-to-tr from-purple-700 to-indigo-700 shadow-2xl rounded-xl -mt-16 p-6 mb-16">
                            <div className="my-4 h-32">
                                <LogoContainer white/>
                            </div>
                            <h1 className="font-extrabold text-lg text-white">User Login</h1>
                            <h3 className="font-light text-sm text-white">Welcome Back</h3>
                        </div>
                        <div className="py-1">
                            <Input  variant="filled" type="text" placeholder="Username" onChange={setusername} value={username} />
                        </div>
                        <div className="py-1">
                            <Input  variant="filled" type="password" placeholder="Password" onChange={setpassword} value={password} />
                        </div>
                        <div className="py-3">
                            <Btn onClick={()=>handleSubmit()}>Login</Btn>
                        </div>
                        <div className="text-red-500">
                            {error}
                        </div>
                    </>}
            </div>
        </div>
    )
}